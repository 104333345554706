* {
  margin: 0;
  padding: 0;
}

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

img {
  max-width: 100%;
}

a {
  text-decoration: none !important;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

button:focus {
  box-shadow: none;
}

/* .form-control:focus:focus {
  box-shadow: none;
} */

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0 !important;
  padding: 0;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('../src/fonts/BebasNeue-Regular.woff2') format('woff2'),
    url('../src/fonts/BebasNeue-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}




@font-face {
  font-family: 'Canela Deck';
  src: url('../src/fonts/CanelaDeck-Black.woff2') format('woff2'),
    url('../src/fonts/CanelaDeck-Black.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Canela Deck';
  src: url('../src/fonts/CanelaDeck-Light.woff2') format('woff2'),
    url('../src/fonts/CanelaDeck-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Canela Deck';
  src: url('../src/fonts/CanelaDeck-Black.woff2') format('woff2'),
    url('../src/fonts/CanelaDeck-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Canela Deck';
  src: url('../src/fonts/CanelaDeck-Medium.woff2') format('woff2'),
    url('../src/fonts/CanelaDeck-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Canela Deck';
  src: url('../src/fonts/CanelaDeck-Thin.woff2') format('woff2'),
    url('../src/fonts/CanelaDeck-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Canela Deck';
  src: url('../src/fonts/CanelaDeck-Regular.woff2') format('woff2'),
    url('../src/fonts/CanelaDeck-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}







@font-face {
  font-family: 'GT America Trial Cm Bd';
  src: url('../src/fonts/GTAmericaTrial-CmBd.woff2') format('woff2'),
    url('../src/fonts/GTAmericaTrial-CmBd.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT America Trial Cm Bl';
  src: url('../src/fonts/GTAmericaTrial-CmBl.woff2') format('woff2'),
    url('../src/fonts/GTAmericaTrial-CmBl.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT America Trial Cm Lt';
  src: url('../src/fonts/GTAmericaTrial-CmLt.woff2') format('woff2'),
    url('../src/fonts/GTAmericaTrial-CmLt.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT America Trial Cm U Lt';
  src: url('../src/fonts/GTAmericaTrial-CmULt.woff2') format('woff2'),
    url('../src/fonts/GTAmericaTrial-CmULt.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT America Trial Cm Rg';
  src: url('../src/fonts/GTAmericaTrial-CmRg.woff2') format('woff2'),
    url('../src/fonts/GTAmericaTrial-CmRg.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT America Trial Cn Bd';
  src: url('../src/fonts/GTAmericaTrial-CnBd.woff2') format('woff2'),
    url('../src/fonts/GTAmericaTrial-CnBd.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT America Trial Cm Md';
  src: url('../src/fonts/GTAmericaTrial-CmMd.woff2') format('woff2'),
    url('../src/fonts/GTAmericaTrial-CmMd.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT America Trial Cm Th';
  src: url('../src/fonts/GTAmericaTrial-CmTh.woff2') format('woff2'),
    url('../src/fonts/GTAmericaTrial-CmTh.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT America Trial Cn Md';
  src: url('../src/fonts/GTAmericaTrial-CnMd.woff2') format('woff2'),
    url('../src/fonts/GTAmericaTrial-CnMd.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT America Trial Cn Lt';
  src: url('../src/fonts/GTAmericaTrial-CnLt.woff2') format('woff2'),
    url('../src/fonts/GTAmericaTrial-CnLt.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT America Trial Cn Bl';
  src: url('../src/fonts/GTAmericaTrial-CnBl.woff2') format('woff2'),
    url('../src/fonts/GTAmericaTrial-CnBl.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT America Trial Cn Th';
  src: url('../src/fonts/GTAmericaTrial-CnTh.woff2') format('woff2'),
    url('../src/fonts/GTAmericaTrial-CnTh.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT America Trial Cn U Lt';
  src: url('../src/fonts/GTAmericaTrial-CnULt.woff2') format('woff2'),
    url('../src/fonts/GTAmericaTrial-CnULt.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT America Trial Cn Rg';
  src: url('../src/fonts/GTAmericaTrial-CnRg.woff2') format('woff2'),
    url('../src/fonts/GTAmericaTrial-CnRg.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT America Trial Exp Bl';
  src: url('../src/fonts/GTAmericaTrial-ExpBl.woff2') format('woff2'),
    url('../src/fonts/GTAmericaTrial-ExpBl.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}










/* home page css start here home page  home page home page*/

/* header section start here */
header {
  position: absolute;
  top: 0;
  z-index: 999;
  width: 100%;
}

.navbar-toggler:focus {
  box-shadow: none;
}

header .navbar-light .navbar-toggler {
  background-color: #fff;
  border-radius: 0;
  outline: none;
  border: 0;
}

header.fixed {
  position: fixed;
  top: 0;
  border-bottom: 1px solid #0000;
  left: 0;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  background-color: #243d59;
  width: 100%;
  box-shadow: 1px 1px #fff;
}

header .navbar-nav .nav-link {
  font-size: 17px;
  font-weight: 500;
  line-height: 21.39px;
  color: #fff !important;
  padding: 0 !important;
  text-align: left;
  font-family: 'GT America Trial Cn Md';
}

header .navbar-nav .nav-link:hover {
  color: #C41230 !important;
  transition: ease-in-out 0.4s;
}

header div#navbarSupportedContent ul {
  gap: 45px;
}

header form.d-flex.online_banking button {
  background-color: #C41230;
  border-radius: 0;
  border: 1px solid #C41230;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.13px;
  width: fit-content;
  padding: 12.5px 34px;
  font-family: 'GT America Trial Cn Md';
  color: #fff;
  text-align: left;
}

header form.d-flex.online_banking button:hover {
  background-color: #fff;
  color: #C41230;
  border-color: #fff;
  transition: ease-in-out 0.5s;
}

header .offcanvas {
  background-color: #243d59;
}

header .offcanvas .btn-close {
  background-color: #fff;
  opacity: 1;
  border-radius: 50%;
  margin: 20px 0 0 0;
  background-size: 12px;
}

header .offcanvas ul {
  display: flex;
  row-gap: 30px;
}

header .offcanvas-header {
  justify-content: end;
}

/* header section end here */



/* home banner css start here */
/* .home-banner {
  background-color: black;
} */

.home-banner .swiper-slide {
  background-repeat: no-repeat;
  background-position: center;
  padding: 13.5% 0 11.5% 0;
  background-size: cover;
  height: 100%;
  position: relative;
}

/* .home-banner-show-pt {
  position: absolute;
  width: 100%;
  bottom: 40px;
  right: 5%;
} */
.home-banner-show-pt {
  position: absolute;
  width: 11%;
  bottom: 40px;
  right: 5%;
}

.home-banner-show-pt .swiper-button-next {
  position: absolute;
  right: 0px;
}

.swiper-button-prev.swiper-button-prev-banner {
  position: absolute;
  bottom: 10px !important;
  left: 6px;
}

.home-banner .inner-next-prev {
  display: flex;
  width: 11%;
  margin: 0 0 0 auto;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.home-banner-show-pt .swiper-button-next::after,
.home-banner-show-pt .swiper-button-prev::after {
  display: none;
}

/* .home-banner-show-pt .swiper-button-next,
.home-banner-show-pt .swiper-button-prev {
  position: static;
} */

.home-banner .swiper-button-prev {
  margin-left: 19px;
}

.home-banner .swiper-slide.one-slide {
  background-image: url(../src/images/banner_home.png);
}

.home-banner .swiper-slide.two-slide {
  background-image: url(../src/images/banner_two.png);
}

.home-banner .swiper-slide.three-slide {
  background-image: url(../src/images/three-slide.png);
}

.home-banner .swiper-slide-active {
  animation: scale 5s ease forwards;
}

.home-banner .swiper-slide h2 {
  font-family: 'Canela Deck';
  color: #fff;
  font-size: 60px;
  font-weight: 400;
  line-height: 72px;
  text-align: left;
}

.home-banner .button_get_loan_banking {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 50px;
}

.home-banner .button_get_loan_banking a {
  font-family: 'GT America Trial Cn Md';
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  line-height: 20.13px;
  text-align: left;
  width: fit-content;
}

.home-banner .button_get_loan_banking a.get_loan {
  padding: 14.4px 52.4px;
}

.home-banner .button_get_loan_banking a.online_banking {
  padding: 14.4px 38px;
}

.home-banner .button_get_loan_banking a:hover {
  background-color: #C41230;
  color: #fff;
  border-color: #C41230;
  transition: ease-in-out 0.5s;
}

.home-banner-show-pt .swiper-pagination {
  font-family: "Bebas Neue", sans-serif;
  font-size: 40px;
  font-weight: 400;
  line-height: 48px;
  color: #fff;
  text-align: right;
  right: 66px;
  left: unset;
  top: -23px;
}

/* home banner css start here */

/* Choose what's right for you css start here */
.home-choose-right {
  background-color: #F5F5F5;
  padding: 100px 0 100px 0;
}

.home-choose-right .swiper {
  margin-top: 65px;
}

.home-choose-right .inner-slide-button {
  position: relative;
  width: 12%;
  padding-top: 40px;
  margin: 0 auto;
  display: flex;
  gap: 30px;
}

.home-choose-right .inner-slide-button .swiper-button-prev:after,
.home-choose-right .inner-slide-button .swiper-button-next:after {
  display: none;
}

.home-choose-right .inner-slide-button .swiper-button-prev,
.home-choose-right .inner-slide-button .swiper-button-next {
  height: auto;
  width: auto;
  margin-top: 0 !important;
  position: static;
}

.home-choose-right .inner-slide-button .swiper-button-prev svg:hover circle,
.home-choose-right .inner-slide-button .swiper-button-next svg:hover circle {
  fill: #c41230;
  transition: ease-in-out 0.4s;
}

.home-choose-right .inner-slide-button .swiper-button-prev svg:hover path,
.home-choose-right .inner-slide-button .swiper-button-next svg:hover path {
  fill: #fff;
  transition: ease-in-out 0.4s;
}

.home-choose-right .choose-inner-ct-st h2 {
  font-size: 42px;
  font-weight: 400;
  line-height: 72px;
  text-align: left;
  font-family: 'Canela Deck';
  color: #142538;
}

.home-choose-right .choose-inner-ct-st p {
  font-family: 'GT America Trial Cm Rg';
  color: #505F70;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
}

.home-choose-right .what-right-box {
  background-color: #fff;
}

.what-right-box.business_box .header-bar {
  background-color: #243D59;
}

.what-right-box.Investment_box .header-bar {
  background-color: #305073;
}

.what-right-box.loan_box .header-bar {
  background-color: #3B628C;
}

.what-right-box.travel_box .header-bar {
  background-color: #4B7CB1;
}

.what-right-box.saving_box .header-bar {
  background-color: #5592D3;
}

.home-choose-right .header-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 24px 18px 24px;
}

.home-choose-right .header-bar h3 {
  font-family: 'Canela Deck';
  font-size: 32px;
  font-weight: 400;
  color: #fff;
  line-height: 38.4px;
  text-align: left;
}

.home-choose-right .body-box {
  padding: 35px 24px 26px 24px;
}

.home-choose-right .body-box .learn-more-button {
  margin-top: 20%;
}

.home-choose-right .body-box .learn-more-button a .icon {
  display: flex;
  align-items: center;
  gap: 10px;
}

.home-choose-right .body-box .learn-more-button a .icon svg:hover circle {
  fill: #243d59;
  transition: ease-in-out 0.4s;
}

.home-choose-right .body-box .learn-more-button a span {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  font-family: 'GT America Trial Cn Md';
  color: #142538;
}

.home-choose-right .body-box .learn-more-button a span:hover {
  color: #a51c30;
  transition: ease-in-out 0.4s;
}

.home-choose-right .body-box p {
  font-size: 18px;
  font-family: 'GT America Trial Cn Th';
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: #142538;
}

/* Choose what's right for you css end here */

/* Services we offer css start here */
.home-service-offer {
  padding: 100px 0;
}

.home-service-offer h1 {
  font-size: 72px;
  font-weight: 400;
  line-height: 72px;
  text-align: center;
  font-family: 'Canela Deck';
  color: #243D59;
  padding-bottom: 50px;
}

.home-service-offer .service-box-one {
  background-color: #F9F1F1;
  padding: 20px;
}

.service-box.service-box-two {
  background-color: #EEF3F9;
  padding: 20px;
}

.service-box.service-box-two:hover {
  background-color: #3B628C;
}

.service-box.service-box-three {
  background-color: #faf7f3;
  padding: 20px;
}

.service-box.service-box-three:hover {
  background-color: #A84F1D;
}

.service-box.service-box-four {
  background-color: #ebedf8;
  padding: 20px;
  height: 100%;
}

.service-box.service-box-four:hover {
  background-color: #524FA7;
  transition: ease-in-out 0.4s;
}

.service-box.service-box-four:hover h3 {
  color: #524FA7;
}

.home-service-offer .service-box {
  overflow: hidden;
  opacity: 0.9;
  transition: ease-in-out 0.4s;
  height: 100%;
}

.home-service-offer .service-box:hover {
  opacity: 1;
  transition: ease-in-out 0.4s;
}

.home-service-offer .service-box .icon {
  margin-bottom: 20px;
}

.home-service-offer .service-box .inner-service-ct {
  transition: ease-in-out 0.7s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.home-service-offer .service-box:hover .inner-service-ct {
  transform: translateY(-30px);
}

.home-service-offer .service-box-one:hover {
  background-color: #C41230;
  transition: ease-in-out 0.4s;
}

.home-service-offer .service-box.service-box-one h3 {
  color: #f9f1f1;
}

.home-service-offer .service-box.service-box-two h3 {
  color: #eef3f9;
}

.home-service-offer .service-box.service-box-three h3 {
  color: #faf7f3;
}

.home-service-offer .service-box.service-box-four h3 {
  color: #ebedf8;
}

.home-service-offer .service-box.service-box-two:hover h3 {
  color: #3B628C;
}

.home-service-offer .service-box.service-box-three:hover h3 {
  color: #A84F1D;
}

.home-service-offer .service-box.service-box-four:hover h3 {
  color: #524FA7;
}

.home-service-offer .service-box-one:hover h3 {
  color: #C41230;
}

.home-service-offer .service-box:hover p {
  color: #fff;
}

.home-service-offer .service-box:hover h2 {
  color: #fff;
}

.home-service-offer .service-box:hover .icon rect {
  fill: #fff;
  opacity: 1;
}

.home-service-offer .service-box-one:hover .icon svg path {
  fill: #C41230;
  stroke: #C41230;
}

.home-service-offer .service-box h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 33px;
  text-align: left;
  font-family: 'Canela Deck';
  color: #161534;
  padding-bottom: 20px;
}

.home-service-offer .service-box h3 {
  font-size: 150px;
  font-weight: 900;
  line-height: 180px;
  text-align: center;
  margin-top: -90px !important;
  font-family: 'Canela Deck';
  text-shadow: 1px 0 0 #E2E8EE, -1px 0 0 #E2E8EE, 0 1px 0 #E2E8EE, 0 -1px 0 #E2E8EE, 1px 1px 0 #E2E8EE, -1px -1px 0 #E2E8EE, 1px -1px 0 #E2E8EE, -1px 1px 0 #E2E8EE, 1px 1px 1px #E2E8EE;
  padding-bottom: 30px;
}

.home-service-offer .service-box p {
  font-size: 17px;
  font-weight: 300;
  line-height: 26px;
  text-align: left;
  color: #636278;
  font-family: 'GT America Trial Cm Rg';
}

/* Services we offer css end here */

/* Why choose us css start here */
.home-why-choose {
  background-color: #524FA7;
  padding: 90px 0 100px 0;
}

.home-why-choose h2 {
  font-family: 'Canela Deck';
  font-size: 72px;
  font-weight: 400;
  color: #fff;
  line-height: 80px;
  letter-spacing: 0.03em;
  text-align: center;
  padding-bottom: 70px;
}

.home-why-choose .inner-box h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 28.8px;
  text-align: left;
  padding-bottom: 18px;
  color: #fff;
  font-family: 'Canela Deck';
}

.home-why-choose .inner-box .icon {
  margin-bottom: 20px;
}

.home-why-choose .inner-box .icon:hover svg rect {
  fill: #C41230;
  transition: ease-in-out 0.4s;
}

.home-why-choose .inner-box .icon:hover svg path {
  /* fill:#fff; */
  transition: ease-in-out 0.4s;
  stroke: #fff;
}

.home-why-choose .inner-box p {
  color: #fff;
  font-size: 17px;
  font-weight: 300;
  line-height: 26px;
  text-align: left;
  font-family: 'GT America Trial Cn Th';
}

/* Why choose us css end here */


/* home-people-have-trusted css start here */
.home-people-have-trusted {
  background-color: #F5F5F5;
  padding: 120px 0 121px 0;
  position: relative;
}

.home-people-have-trusted .swiper-button-prev,
.home-people-have-trusted .swiper-button-next {
  background-color: #fff;
  border: 1.5px solid #E9EAF0;
  border-radius: 50%;
  padding: 0;
  width: 46px;
  height: 46px;
  margin: 0 auto;
}

.home-people-have-trusted .swiper-button-prev:hover,
.home-people-have-trusted .swiper-button-next:hover {
  background-color: #a51c30;
  transition: ease-in-out 0.4s;
}

.home-people-have-trusted .swiper-button-prev:hover svg path,
.home-people-have-trusted .swiper-button-next:hover svg path {
  fill: #fff;
  transition: ease-in-out 0.4s;
}

.home-people-have-trusted .swiper-button-prev svg,
.home-people-have-trusted .swiper-button-next svg {
  width: 11px;
  height: 18px;
  object-fit: cover;
  object-position: center;
}

.home-people-have-trusted .swiper-button-prev:after,
.home-people-have-trusted .swiper-button-next:after {
  display: none;
}

.home-people-have-trusted h2 {
  font-family: 'Canela Deck';
  font-size: 50px;
  font-weight: 400;
  line-height: 65px;
  text-align: center;
  color: #161534;
}

.home-people-have-trusted .swiper-wrapper {
  padding-top: 40px;
}

.home-people-have-trusted .swiper-slide {
  height: 100%;
  border: 2px solid #E9EAF0;
  background-color: #fff;
  transition: ease-in-out 0.7s;
  cursor: pointer;
}

.people_trusteded-review {
  padding: 40px 26px 29px 26px;
}

.home-people-have-trusted .swiper-slide:hover {
  transform: translateY(-10px);
}

.people_trusteded-review p {
  font-size: 19px;
  font-weight: 400;
  line-height: 31px;
  text-align: left;
  font-family: 'GT America Trial Cm Rg';
  color: #161534;
  padding: 25px 0;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.home-people-have-trusted .auther_name {
  border-top: 1px solid #ddd;
  padding-top: 15px;
  margin-top: 20%;
}

.home-people-have-trusted .auther_name h3 {
  font-family: 'GT America Trial Cm Bd';
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  color: #161534;
}

.home-people-have-trusted .auther_name h4 {
  font-size: 15px;
  font-weight: 300;
  line-height: 28px;
  text-align: left;
  color: #43435A;
  font-family: 'GT America Trial Cm Lt';
}

/* home-people-have-trusted css end here */

/* One short form css start here */
.home-one-short-tearm {
  background-color: #C41230;
  padding: 80px 0 78px 0;
}

.home-one-short-tearm .inner-short-ct h2 {
  font-size: 20px;
  font-weight: 700;
  font-family: 'GT America Trial Cm Bd';
  line-height: 25.16px;
  color: #fff;
  text-align: left;
  text-transform: uppercase;
  padding-bottom: 19px;
}

.home-one-short-tearm .inner-short-ct h3 {
  font-size: 40px;
  font-family: 'GT America Trial Cm Bd';
  font-weight: 700;
  line-height: 32px;
  color: #fff;
  text-align: left;
}

.home_proceed_to_form p {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  font-family: 'GT America Trial Cn Th';
  color: #fff;
  padding-bottom: 30px;
}

.home_proceed_to_form a {
  background-color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 22.64px;
  text-align: left;
  font-family: 'GT America Trial Cn Md';
  padding: 15.3px 49.3px;
  color: #C41230;
  display: flex;
  width: fit-content;
}

.home_proceed_to_form a:hover {
  background-color: #243d59;
  transition: ease-in-out 0.4s;
  color: #fff;
}

/* One short form css end here */

/* footer section start here */
footer {
  background-color: #243D59;
}

footer h2 {
  font-size: 20px;
  font-weight: 700;
  line-height: 25.16px;
  letter-spacing: 0.01em;
  text-align: left;
  font-family: 'GT America Trial Cm Bd';
  color: #fff;
  text-transform: uppercase;
  padding-bottom: 20px;
}

footer ul li a {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: #fff;
  font-family: 'GT America Trial Cn Th';
  text-align: left;
  text-decoration: none;
}

footer ul li a:hover {
  color: #c41230;
  transition: ease-in-out 0.4s;
}

footer .footer-menu_row p {
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  text-align: left;
  font-family: 'GT America Trial Cn Th';
  color: #fff;
  padding-bottom: 20px;
}

footer .footer-menu_row ul {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-left: 0;
}

footer .footer-menu_row ul li {
  width: 100%;
}

footer .footer-menu_row .newsletter input {
  background-color: transparent;
  border: 0;
  padding: 0;
  font-family: 'Bebas Neue';
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  /* text-transform: capitalize; */
  text-align: left;
  color: #FFFFFF;
}

footer .footer-menu_row .newsletter input::placeholder {
  color: #ddd;
}

footer .footer-menu_row .newsletter span.input-group-text {
  background-color: transparent;
  padding: 0;
  border: 0;
}

footer .footer-menu_row .newsletter button {
  background-color: transparent;
  padding: 0;
  border: 0;
  outline: none;
}

footer .footer-menu_row .newsletter button svg:hover path {
  fill: #c41230;
  transition: ease-in-out 0.4s;
}

footer .footer-menu_row .newsletter .input-group {
  border-bottom: 2px solid #FFFFFF;
  /* padding-bottom: 23px; */
}

footer .footer-menu_row .newsletter .input-group input {
  padding: 2px 0px 20px 4px;
}

footer .footer-menu_row {
  padding: 94px 0 63px 0;
}

footer .copy_right {
  border-top: 1px solid #FFFFFF;
  padding: 20px 0;
}

footer .copy_right h4 {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  font-family: 'GT America Trial Cn Th';
  color: #fff;
  text-transform: capitalize;
  text-align: left;
}

footer .copy_right ul {
  display: flex;
  justify-content: end;
  gap: 30px;
}

footer .copy_right ul li a svg:hover path {
  fill: #c41230;
  transition: ease-in-out 0.4s;
}

/* footer section end here */

/* our product page css start here */

.saley-advance .row-salery.row {
  align-items: center;
  justify-content: space-between;
}

.row-salery.row .content h2 {
  font-family: 'Canela Deck';
  font-size: 42px;
  font-weight: 400;
  line-height: 72px;
  color: #243D59;
  text-align: left;
}

.row-salery.row .content {
  padding: 0 50px 0 50px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
}

.row-salery.row .content .icon {
  width: 100%;
}

.row-salery.row .content p {
  font-family: 'GT America Trial Cm Rg';
  font-size: 18px;
  font-weight: 400;
  color: #505F70;
  line-height: 28px;
  text-align: left;
}

.our_row-wraper {
  display: flex;
  flex-wrap: wrap;
  gap: 100px;
}

.our_row-wraper .saley-advance {
  width: 100%;
}

.our_row-wraper .saley-advance:first-child {
  padding-top: 100px;
}

.our_row-wraper .saley-advance:nth-child(even) {
  background-color: #F5F5F5;
  padding: 100px 0;
}

.our_row-wraper .saley-advance:nth-child(even) .row-salery.row .content {
  padding-left: 0;
}

.our_row-wraper .saley-advance:nth-child(even) .row-salery.row {
  flex-direction: row-reverse;
}

/* our product page css ned here */

/* home page css end here  home page home page home page*/


/* contact page css start here */
.contact_banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 8% 0 4.4% 0;
}

.contact_banner h1 {
  font-family: 'Canela Deck';
  color: #fff;
  font-size: 74px;
  font-weight: 400;
  line-height: 72px;
  text-align: left;
}

.contact_banner .description {
  padding: 70px 0 0 0;
}

.contact_banner p {
  font-family: 'Canela Deck';
  font-size: 32px;
  font-weight: 400;
  line-height: 46px;
  border-left: 3px solid #fff;
  color: #fff;
  text-align: left;
  padding-left: 27px;
}

.general_ct-info {
  background-color: #F5F5F5;
  padding: 80px 0 100px 0;
}

.general_ct-info h2 {
  font-family: 'Canela Deck';
  font-size: 50px;
  font-weight: 400;
  line-height: 72px;
  text-align: center;
  padding-bottom: 40px;
  color: #243D59;
}

.general_ct-info .box {
  background-color: #fff;
  border-top: 5px solid #C41230;
  padding: 40px 65px;
  transition: ease-in-out 0.5s;
  height: 100%;
}

.general_ct-info .box .icon {
  text-align: center;
}

.general_ct-info .box .icon svg {
  width: 33.13px;
  object-position: center;
  object-fit: contain;
  height: 33.13px;
}

.general_ct-info .box h3 {
  font-family: 'GT America Trial';
  font-size: 20px;
  font-weight: 700;
  line-height: 72px;
  text-align: center;
  text-transform: uppercase;
  vertical-align: top;
  color: #243D59;
  text-shadow: 0px 0 #243D59, 0px 0 #243D59;
}

.general_ct-info .box .description {
  border-top: 1px solid #ddd;
  padding-top: 20px;
  text-align: center;
}

.general_ct-info .box p,
.general_ct-info .box a {
  font-family: 'GT America Trial',
    'sans-serif';
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  color: #505F70;
  text-align: center;
  text-align: center;
  word-spacing: 2px;
  width: 100%;
}

/* contact page cs end here */

@media only screen and (max-width:1800px) {
  .home-banner .swiper-button-prev {
    margin-left: -10px;
  }
}

@media all and (max-width:1440px) {
  .home-banner-show-pt .swiper-pagination {
    font-size: 34px;
    right: 41px;
  }

  .home-banner-show-pt {
    width: 9%;
  }
}

@media only screen and (max-width:1150px) {
  .home-banner-show-pt {
    width: 13%;

  }
}

@media all and (max-width:1024px) {
  .home-banner-show-pt .swiper-pagination {
    font-size: 17px;
  }

  .home-service-offer .service-box h2 {
    font-size: 21px;
    line-height: 27px;
    padding-bottom: 20px;
  }

  .home-people-have-trusted .swiper-button-prev,
  .home-people-have-trusted .swiper-button-next {
    background-color: #fff;
    top: 60%;
  }

  .home-banner .swiper-button-prev {
    margin-left: 16px;
  }
}

@media all and (max-width:851px) {
  footer .footer-menu_row {
    padding: 50px 10px;
  }

  .our_row-wraper .saley-advance:nth-child(even) {
    padding: 50px 10px;
  }

  .our_row-wraper .saley-advance:first-child {
    padding-top: 50px;
  }

  .contact_banner p {
    font-size: 20px;
    line-height: 27px;
  }

  .contact_banner h1 {
    font-size: 43px;
    line-height: normal;
    text-align: left;
  }

  .general_ct-info {
    padding: 51px 10px;
  }

  .general_ct-info h2 {
    font-size: 31px;
    line-height: 39px;
    padding-bottom: 20px;
  }

  .general_ct-info .row {
    row-gap: 20px;
  }

  .general_ct-info .box {
    padding: 40px;
  }

  .contact_banner {
    padding: 33% 0 16.4% 0;
  }

  .contact_banner .description {
    padding: 20px 0 0 0;
  }

  .our_row-wraper {
    gap: 50px;
  }

  .our_row-wraper .saley-advance {
    padding: 0 10px;
  }

  .row-salery.row .content {
    padding: 50px 0 0 0;
  }

  .row-salery.row .content h2 {
    font-size: 25px;
    line-height: 26px;
  }

  .home-choose-right .inner-slide-button {
    width: 27%;
  }

  .our_row-wraper .row-salery.row .content p {
    font-size: 16px;
    line-height: 25px;
  }

  .home-banner .button_get_loan_banking {
    margin-top: 28px;
  }

  footer .footer-menu_row ul {
    gap: 10px;
    padding-bottom: 30px;
  }

  footer h2 {
    padding-bottom: 9px;
  }

  .home-service-offer {
    padding: 50px 10px 60px 10px;
  }

  .home-why-choose {
    padding: 50px 10px;
  }

  .home-why-choose h2,
  .home-people-have-trusted h2 {
    font-size: 37px;
    line-height: 40px;
  }

  .home-why-choose h2 br,
  .home-people-have-trusted h2 br {
    display: none;
  }

  .home-people-have-trusted,
  .home-choose-right,
  .home-one-short-tearm {
    padding: 50px 10px;
  }

  .home-choose-right .choose-inner-ct-st h2 {
    font-size: 26px;
    line-height: 27px;
  }

  .home-service-offer .service-box {
    margin-bottom: 30px;
    height: 90%;
  }

  .home-choose-right .body-box p {
    font-size: 15px;
    font-weight: 600;
    line-height: 27px;
  }

  .home-choose-right .body-box {
    padding: 20px;
  }

  .home-choose-right .inner-slide-button .swiper-button-prev,
  .home-choose-right .inner-slide-button .swiper-button-next {
    width: 40px !important;
    height: 40px !important;
  }

  .swiper-button-next svg,
  .swiper-button-prev svg {
    width: 40px !important;
    height: 40px !important;
  }

  .home-banner .swiper-button-prev {
    margin-left: 170px;
  }

  .home-choose-right .swiper {
    margin-top: 34px;
  }

  .home-choose-right .choose-inner-ct-st p {
    padding-top: 15px;
  }

  .home-service-offer h1 {
    font-size: 37px;
    line-height: 33px;
    padding-bottom: 35px;
  }

  .home-choose-right .header-bar h3 {
    font-size: 25px;
    line-height: 32.4px;
  }

  .home-choose-right .header-bar .icon svg {
    width: 30px;
    height: 30px;
    object-fit: cover;
    object-position: center;
  }

  .home-one-short-tearm .inner-short-ct h3 {
    font-size: 29px;
    line-height: 32px;
  }

  .home-one-short-tearm .inner-short-ct h2 {
    font-size: 17px;
    padding-bottom: 8px;
  }

  .home_proceed_to_form p {
    font-size: 17px;
    line-height: 26px;
    padding: 12px 0 20px 0;
  }

  .home-banner .swiper-slide h2 {
    font-size: 30px;
    line-height: 35px;
  }

  .home-banner .swiper-slide {
    padding: 31% 0 28% 0;
  }

  .home-banner .home-banner-show-pt {
    bottom: 23px;
    right: 10%;
  }

  .home-banner .inner-next-prev {
    width: 31%;
  }

  .home-banner-show-pt .swiper-pagination {
    right: 32px;
    color: #fff;
    font-size: 22px;
  }

  .home-banner .button_get_loan_banking a {
    font-size: 14px;
  }

  .home-banner .button_get_loan_banking a.get_loan {
    padding: 10.4px 36.4px;
  }

  .home-banner .button_get_loan_banking a.online_banking {
    padding: 10.4px 23px;
  }

  .home-service-offer .service-box h3 {
    font-size: 90px;
    font-weight: 900;
    line-height: 90px;
    margin-top: -57px !important;
  }

  .home-why-choose h2 {
    padding-bottom: 10px;
  }

  .home-why-choose .inner-box h3 {
    font-size: 18px;
    line-height: 28.8px;
    padding-bottom: 4px;
  }

  .home-why-choose .inner-box .icon svg {
    width: 40px;
    height: 40px;
    object-fit: cover;
    object-position: center;
  }

  .home-why-choose .inner-box .icon {
    margin: 20px 0;
  }

  .home-why-choose .inner-box p {
    font-size: 14px;
    line-height: 23px;
  }

  .home-people-have-trusted .swiper-button-prev,
  .home-people-have-trusted .swiper-button-next {
    position: static;
  }

  .home-people-have-trusted .people-review-nt_pt {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 33px;
    width: 38%;
    margin: 0 auto;
  }

  .home-people-have-trusted .swiper-button-prev svg,
  .home-people-have-trusted .swiper-button-next svg {
    width: 15px !important;
    height: 15px !important;
  }

  .people_trusteded-review p {
    font-size: 17px;
    line-height: 25px;
    padding: 13px 0;
    height: 120px;
  }

  header .navbar-light .navbar-toggler-icon {
    background-image: url(../src/images/menu-svgrepo-com.svg) !important;
    box-shadow: none;
  }

  footer .footer-menu_row .newsletter .input-group {
    border-bottom: 1px solid #ddd;
    padding-bottom: 7px;
  }

  .home-service-offer .service-box h2 {
    font-size: 20px;
    line-height: 25px;
    padding-bottom: 6px;
    text-align: center;
  }

  .home-service-offer .service-box p {
    text-align: center;
  }

  .home-service-offer .service-box:hover .inner-service-ct {
    transform: translateY(-10px);
  }

  .home-people-have-trusted h2 {
    font-size: 29px;
    line-height: 36px;
  }

  header {
    padding: 0 10px;
  }

  footer .copy_right h4 {
    font-size: 13px;
    line-height: 21px;
  }

  .home-banner-show-pt .swiper-button-next svg:hover path,
  .home-banner-show-pt .swiper-button-prev svg:hover path {
    fill: #c41230;
    transition: ease-in-out 0.4s;
  }

  .home-service-offer .service-box p br {
    display: none;
  }

  .home-service-offer .service-box h2 br {
    display: none;
  }

  .home-service-offer .service-box .icon {
    text-align: center;
  }

  .home-people-have-trusted .swiper-wrapper {
    padding-top: 31px;
  }
}

/* new css added */
/* Loan Page Css */

.stepper-container {
  width: 100%;
  background-color: #276FBF;
  padding: 48px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-top: 90px
}

.stepper-container .row-step {
  width: 100%;
  max-width: 100%
}




@media (max-width: 480px) {

  .step-label {
    display: none
  }

  .stepper-container {
    padding: 16px 8px
  }
}

.stepper-wrapper {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative
}

.step_user {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.step_user_box ul {
  padding-left: 0;
  margin: 0;
  text-align: center;
}

.step_user_box ul li p {
  color: #fff;
  font-size: 14px;
  opacity: .5;
}

.step_user_box ul li span {
  width: 22px;
  height: 22px;
  display: flex;
  color: #fff;
  background: #276fbf;
  border-radius: 50px;
  justify-content: center;
  margin: 24px auto;
  border: 2px solid #93B7DF;
  z-index: 9;
  position: relative;
}

.step_user::before {
  position: absolute;
  /* content: ""; */
  width: 90%;
  height: 2px;
  border-bottom: 2px dashed #fff;
  top: 14px;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
}

.step_user {
  position: relative;
}

.step_user_box ul li svg {
  height: 36px;
  width: 36px;
  opacity: .5;
}

.step_user_box.active ul li svg {
  opacity: 1;
}

.step_user_box.finish ul li svg {
  opacity: 1;
}

.step_user_box.active span {
  border: 6px solid #fff;
}

.step_user_box.active ul li p {
  opacity: 1;
}

.step_user_box.finish ul li p {
  opacity: 1;
}

.my_contact h2 {
  font-size: 28px;
  font-weight: 600;
}

.my_contact h3 {
  font-size: 16px;
  margin: 13px 0 20px !important;
  border-bottom: 1px solid #dedede;
  padding-bottom: 15px;
}

.my_contact h4 {
  font-size: 16px;
  margin: 13px 0 20px !important;
}

.my_contact .form-group input {
  height: 45px;
}

.my_contact .form-group label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.my_contact .form-group {
  margin-bottom: 25px;
}

.my_contact .form-group textarea {
  height: 115px;
}

.my_contact button {
  background-color: #243d59;
  border: 1px solid #243d59;
  font-size: 16px;
  font-weight: 500;
  line-height: 14.13px;
  width: fit-content;
  padding: 12.5px 54px;
  font-family: 'GT America Trial Cn Md';
  color: #fff;
  border-radius: 4px;
}

.my_contact .map iframe {
  width: 100%;
  height: 460px;
}

.my_contact {
  padding: 30px 0;
}

.step_user {
  max-width: 870px !important;
  width: 100% !important;
}

.step_user_box.active:after {
  position: absolute;
  background: #ffffff;
  content: "";
  width: 100%;
  height: 2px;
  left: 50%;
  top: 15px;
  bottom: 0;
  margin: auto;
}

.step_user_box.finish:after {
  position: absolute;
  background: #ffffff;
  content: "";
  width: 100%;
  height: 2px;
  left: 50%;
  top: 15px;
  bottom: 0;
  margin: auto;
}

.step_user_box:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  left: 50%;
  top: 15px;
  bottom: 0;
  margin: auto;
  border: 1px dashed #fff !important;
}

.step_user_box {
  position: relative;
  width: 100%;
  min-width: 85px;
  max-width: 20%;
}

.step_user_box:last-child:after {
  display: none;
}

.step_user_box.active.first:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  left: 50%;
  top: 15px;
  bottom: 0;
  margin: auto;
  border: 1px dashed #fff;
}

.step_user_box.active.first:after {
  background: transparent;
}





@media (max-width: 991.98px) {
  .home-banner .swiper-button-prev {
    margin-left: 13px;
  }

  div#navbarSupportedContent {
    background: #243d59;
    padding: 20px;
    text-align: right;
    color: black;
  }

  header .navbar-nav .loan-nav-link {
    color: #fff !important;
    padding: 0 !important;
    text-align: left;
  }

  header .navbar-nav li {
    display: flex;
    justify-content: left;
  }

  form.d-flex.online_banking {
    justify-content: left;
    margin-top: 40px;
  }

  .my_contact .map iframe {
    height: 300px;
  }

  .my_contact h2 {
    font-size: 24px;
    margin-top: 16px !important;
  }

  .my_contact h3 {
    font-size: 14px;
    margin: 12px 0 12px !important;
    padding-bottom: 12px;
  }

  .my_contact h4 {
    font-size: 14px;
    margin: 8px 0 8px !important;
  }

  .my_contact .form-group input {
    font-size: 14px;
  }
}



@media (max-width: 768px) {
  .home-banner-show-pt {
    width: 34%;
  }

  .home-banner-show-pt .swiper-button-next {
    position: absolute;
    right: -7px;
  }

  .home-banner .swiper-button-prev {
    margin-left: 148px;
  }

  .step_user_box.active.first:after {
    top: 8px;
  }

  .my_contact .map iframe {
    height: 250px;
  }

  .step_user_box {
    min-width: 54px;
  }

  .step_user_box ul li p {
    font-size: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .step_user_box:after {
    top: 8px;
  }

  .step_user_box.active:after {
    top: 5px;
  }

  .step_user_box.active span {
    border: 4px solid #fff;
  }

  .step_user_box ul li span {
    width: 16px;
    height: 16px;
    margin: 13px auto;
  }

  .step_user_box ul li svg {
    height: 24px;
    width: 24px;
  }

  .loan_title {
    font-size: 13px !important
  }
}


/* Loan Personal Contact Page  */

.loan_contact .input_box input {
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 0px;
  border: 1px solid #E5E9ED;
  padding-left: 25px;
  color: #6D7175;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px
}

.loan_contact .input-dollar {
  position: relative;
}

.loan_contact .input-dollar::after {
  content: "$";
  position: absolute;
  font-size: 15px;
  width: 20px;
  height: 20px;
  top: 24px;
  left: 10px;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #494848;
}

.loan_contact .input_box input::placeholder {
  font-family: 'GT America Trial', sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
}

.loan_contact .input_box textarea::placeholder {
  font-family: 'GT America Trial', sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.loan_contact .input_box label {
  width: 100%;
}

.loan_contact .input_box span {
  color: #C41230;
  margin-left: 2px;
  font-size: 12px;
  position: relative;
  top: -2px;
}

/* .loan_contact button {
  width: 126px;
  height: 47px;
  color: #fdfdfd;
  background-color: #C41230;
  border: none;
  margin-top: 25px;
} */

.loan_contact button:first-child {
  margin-right: 20px;
  background-color: #B0B9C3;
}

.disabled_back {
  cursor: not-allowed !important;
}

.loan_contact {
  padding-bottom: 100px;
}

.loan_contact button {
  font-family: 'GT America Trial', sans-serif;
  font-size: 15px;
  font-weight: 600;
  width: 155px;
  height: 45px;
  color: #fdfdfd;
  background-color: #C41230;
  border: none;
  margin-top: 25px;
}

.details {
  margin-top: 40px;
  font-family: 'GT America Trial', sans-serif;
  font-size: 26px;
  font-weight: 600;
  text-decoration-skip-ink: none;
  color: #243D59
}

.input_box input[type="file"] {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 0px;
  border: 2px dashed #87A1BF;
  padding-left: 25px;
}

.input_box input[type=file]::file-selector-button {
  padding: .2em .4em;
  border-radius: 4px;
  color: #276FBF;
  font-family: 'GT America Trial', sans-serif;
  font-size: 15px;
  font-weight: 600;
  border: 0;
  background-color: #E5EFFD;
  transition: 1s;
}

.input_box.sum-box-choose input {
  padding: 12px 25px;
}

.input_box select {
  width: 100%;
  background-image: url('./images/Vector\ \(2\).svg') !important;
  background-size: 10px;
  padding: 9px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #E5E9ED;
  padding-left: 25px;
  font-family: 'GT America Trial', sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 28px;
  text-align: left;
}

.input_box.choose-field {
  display: flex;
  padding: 13.5px;
  margin-top: 51px;
  border: 1px solid #E5E9ED;
  gap: 10px;
}

div#radio-wr .input_box.choose-field {
  margin: 0;
}

.input_box.choose-field.second-tab-radio {
  background-color: #276FBF0D;
  display: flex;
  padding: 13.5px;
  margin-top: 51px;
  border: 1px solid #87A1BF;
  gap: 10px;
}

.input_box.choose-field input {
  width: 20px;
  margin-bottom: 0;
  border: 1px solid #243D59;
  height: 20px;
  padding: 7px;
  margin-top: 0;
  border-radius: 50%;
}

.input_box.choose-field .form-check-input:focus {
  box-shadow: none;
}

.input_box.choose-field .form-check-input:checked+label .input_box.choose-field {
  background-color: #ff0033;
  ;
}

.input_box.choose-field .form-check-input:checked {
  background-color: #276FBF;
  box-shadow: none;
  border-color: #276FBF;
}

.input_box.choose-field label {
  font-size: 15px;
  color: #56606A;
  font-weight: 400;
  font-family: 'GT America Trial', sans-serif;
  line-height: 19px;
}

.loan_title {
  color: #56606A;
  font-family: 'GT America Trial', sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 2px;
  margin-top: 20px;
}

div#box-item .loan_title {
  margin-top: 11px;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  position: absolute;
}

.success {
  color: rgb(1, 253, 22);
  font-size: 12px;
  margin-top: 5px;
  position: absolute;
}

.stepper-buttons {
  display: flex;
  gap: 16px;
  margin-top: 16px;
}

.stepper-button {
  padding: 8px 24px;
  border-radius: 4px;
  background-color: white;
  color: #2563eb;
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
}

.stepper-button:hover:not(.disabled) {
  background-color: rgba(255, 255, 255, 0.9);
}

.stepper-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.dollar_input {
  padding-left: 34px !important;

}

.back_btn {
  background-color: #fffafa !important;
  border: 1px solid #C41230 !important;
  color: #C41230 !important;
}

div#radio-wr .first-tab-radio {
  background-color: #276FBF0D;
  display: flex;
  padding: 13.5px;
  margin-top: 51px;
  border: 1px solid #87A1BF;
  gap: 10px;
}

div#box-item {
  opacity: 0;
}

.form-select {
  color: #505F70 !important;
  border: 1px solid #E5E9ED;
  transition: border-color 0.2s ease;
}

.form-select:focus {
  border-color: #E5E9ED !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.form-select:hover {
  background-color: transparent;
  color: #505F70 !important;
  cursor: default;
}

header.loan-fixed {
  position: fixed;
  top: 0;
  border-bottom: 1px solid #0000;
  left: 0;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  background-color: #ffffff;
  width: 100%;
  box-shadow: 1px 1px #030303;
}

header .navbar-nav .loan-nav-link {
  font-size: 17px;
  font-weight: 500;
  line-height: 21.39px;
  color: #0f0f0f;
  padding: 0 !important;
  text-align: left;
  font-family: 'GT America Trial Cn Md';
}

header .navbar-nav .nav-link:hover {
  color: #C41230 !important;
  transition: ease-in-out 0.4s;
}

/* FinanceAccordion.css */

.accordion-container {
  max-width: 941px;
  max-height: 923px;
  margin: 20px auto;
  font-family: Arial, sans-serif;
  margin-top: 70px;
  margin-bottom: 100px;
}

.accordion-item {
  border: 1px solid #E5E9ED !important;
  border-radius: 4px;
  margin-bottom: 10px;
}

.accordion-header {
  font-family: 'GT America Trial', sans-serif;
  font-size: 18px;
  font-weight: 550;
  line-height: 33px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: 100%;
  padding: 15px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  border: none;
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-right: 20px;
}

.accordion-header.active {
  border-bottom: 1px solid #E5E9ED;
}

.icon {
  font-size: 20px;
  font-weight: bold;
  color: #161538;
  transform: scale(1);
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.accordion-content.open {
  max-height: 100%;
  padding: 20px;
}

.accordion-content ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 15px;
}

.accordion-content li {
  margin-bottom: 10px;
  line-height: 1.5;
  color: #636278 !important
}

.accordion-content strong {
  font-weight: 600;
}

.accordion-content ul li {
  list-style: disc !important;
}

.accordion-content ol li {
  list-style: auto !important;
}

.custom-modal-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-close-button {
  color: #C41230 !important;
  opacity: 1;
}

.modal-body-centered {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.custom-close-button:hover {
  color: #8b0c22 !important;
}

.custom-close-btn {
  background-color: #C41230 !important;
  border-color: #C41230 !important;
}

.custom-close-btn:hover {
  background-color: #8b0c22 !important;
  border-color: #8b0c22 !important;
}

.step_user_box.finish ul li span {
  border-color: #fff;
}

.invest-benefits {
  padding: 100px 0 110px;
}

.invest-benefits h1 {
  font-family: 'Canela Deck';
  font-size: 50px;
  font-weight: 400;
  line-height: 72px;
  text-align: center;
  color: #243D59;
  max-width: 830px;
  margin: 0 auto 50px !important;
}

.invest-benefits .benefits-box {
  padding: 23px;
  height: 100%;
  border-radius: 16px;
}

.invest-benefits .benefits-box .content h2 {
  font-size: 19px;
  line-height: 26px;
  color: #161534;
  font-family: 'Canela Deck';
  font-weight: 400;
  margin: 15px 0 !important;
}

.invest-benefits .benefits-box .content p {
  font-size: 14px;
  font-weight: 300;
  line-height: 20.67px;
  color: #636278;
  font-family: 'GT America Trial Cn Rg';
}

.invest-benefits .benefits-box.one {
  background: #EEF3F9;
}

.invest-benefits .benefits-box.two {
  background: #EBEDF8;
}

.invest-benefits .benefits-box.three {
  background: #FAF7F3;
}

.invest-benefits .benefits-box.four {
  background: #F9F1F1;
}

.invest-benefits .benefits-box.five {
  background: #E8FAFE;
}

.requirement-sec {
  padding: 100px 0;
}

.requirement-sec .left-content {
  margin-right: 65px;
}

.requirement-sec .left-content h2 {
  font-family: 'Canela Deck';
  font-size: 50px;
  font-weight: 400;
  line-height: 72px;
  color: #243D59;
}

.requirement-sec .left-content p {
  font-family: 'GT America Trial Cn Rg';
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: #505F70;
  max-width: 450px;
  margin-bottom: 10px !important;
}

.requirement-sec .left-content ul {
  margin: 30px 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 30px 0px;
}

.requirement-sec .left-content ul li {
  position: relative;
  color: #243D59;
  font-size: 20px;
  font-weight: 500;
  line-height: 33px;
  max-width: 50%;
  width: 100%;
  font-family: 'GT America Trial Cn Md';
  padding-left: 50px;
}

.requirement-sec .left-content ul li::before {
  content: '';
  position: absolute;
  background-image: url('./images/Group\ 1000004063.png');
  width: 35px;
  height: 35px;
  left: 0;
  top: 0;
}

.requirement-sec .left-content h3 {
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 15px !important;
  color: #243D59;
  font-family: 'GT America Trial Cn Bd';
}

@media only screen and (max-width: 767px) {
  header form.d-flex.online_banking button {
    text-align: center;
    width: 100%;
  }

  .home-banner .swiper-button-prev {
    /* position: absolute; */
    margin-left: 15px;
  }

  .home-choose-right .swiper {
    margin-top: 34px;
  }

  .home-choose-left .swiper {
    margin-top: 34px;
  }

  .invest-benefits {
    padding: 50px 0;
  }

  .invest-benefits h1 {
    font-size: 30px;
    line-height: normal;
    margin: 0 auto 30px !important;
  }

  .invest-benefits .row .col {
    flex: 1 0 100%;
  }

  .invest-benefits .row {
    row-gap: 20px;
  }

  .requirement-sec {
    padding: 50px 0;
  }

  .requirement-sec .left-content {
    margin-right: 0;
    margin-bottom: 30px;
  }

  .requirement-sec .left-content h2 {
    font-size: 30px;
    line-height: normal;
    margin-bottom: 10px !important;
  }

  .requirement-sec .left-content p {
    font-size: 16px;
    line-height: 24px;
  }

  .requirement-sec .left-content ul {
    margin: 20px 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px 0px;
  }

  .requirement-sec .left-content ul li {
    font-size: 18px;
    max-width: 100%;
    padding-left: 40px;
  }

  .requirement-sec .left-content h3 {
    font-size: 26px;
  }
}

.loader-container svg circle {
  stroke: #ffffff;
}

.loader-container {
  height: 100vh;
  background: #243d59;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-loader-container svg circle {
  stroke: #ffffff;
}

.admin-loader-container {
  justify-content: center;
  align-items: center;
  margin-top: 200px;
}

/* Disable up/down arrows for number inputs */
input[type="number"] {
  -moz-appearance: textfield;
  /* For Firefox */
  appearance: textfield;
  /* For modern browsers */
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  /* For WebKit browsers (Chrome, Safari) */
  margin: 0;
}

/* .navbar-toggler.toggle-active .navbar-toggler-icon {
  background-image: none;
}

.navbar-toggler.toggle-active .navbar-toggler-icon::before,
.navbar-toggler.toggle-active .navbar-toggler-icon::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #000;
  left: 0;
}

.navbar-toggler.toggle-active .navbar-toggler-icon::before {
  transform: rotate(45deg);
}

.navbar-toggler.toggle-active .navbar-toggler-icon::after {
  transform: rotate(-45deg);
} */

.hamburger-menu {
  display: none;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 10px;
  position: relative;
  z-index: 1000;
}

.cross {
  background-color: white;
}



@media (max-width: 767.98px) {
  .home-banner-show-pt {
    width: 21%;
  }
}




@media (max-width: 575.98px) {

  .home-banner-show-pt {
    width: 23% !important;
  }

}

@media (max-width: 540px) {

  .home-banner-show-pt {
    width: 25% !important;
  }

}

@media (max-width: 480px) {
  .home-banner-show-pt {
    width: 30% !important;
  }

}

@media (max-width: 375px) {
  .home-banner-show-pt {
    width: 34% !important;
  }
}

@media (max-width: 320px) {
  .home-banner-show-pt {
    width: 38% !important;
  }
}